@import '../../assets/scss/variable.scss';

.listNotes {
  width: 100%;
  padding: 2rem 5rem;
  background-color: #eaeaea7a;

  .row {
    position: relative;
    width: 100%;
    .add {
      background-color: $primary;
      border: none;
      border-radius: 10em;
      color: #FFF;
      cursor: pointer;
      padding: 1.5em 3em;
      position: absolute;
      right: 0;
      top: 0;;
      &:hover {
        background-color: $primaryAccent1;
      }
    }
  }
  .row.title-row {
    margin-bottom: 3.5rem;
  }

  .list {
    display: inline-block;
    width: 35%;
  }

  .itemNote {
    cursor: pointer;
    padding: 1rem;
    text-align: center;
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: top;

    .bubbleColor {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 100%;
      position: absolute;
      left: 3rem;
      top: 3.5rem;

      &.active {
        background-color: #549d66;
      }
      &.inactive {
        background-color: #EF4136;
      }
    }

    .content {
      background-color: #FFF;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(10,34,53,.1);
      padding: 3rem 1rem;

      .note {
        font-weight: 600;
        font-size: 1.5em;
      }

      .noteIcon {
        margin: 0em 1em;
        text-transform: uppercase;
        width: 30%;
      }
    }
  }

  
}

