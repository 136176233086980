@import '../variable.scss';
@import './button.scss';

img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.container {
    width: 100%;
    padding: 0 1.5rem;
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.align-center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.main-content {
    padding-left: $sidebarWidth;
    width: 100%;
    background-color: $grey;
    height: auto;
    min-height: 100vh;
}

.main-content-container {
    height: calc(100vh - 10rem);
    width: 100%;
    margin: 0 5rem;
}

.is-card {
    background-color: white;
    border-radius: 5rem;
}

h1 {
    color: #484848;
    font-weight: 400;
    margin: 2rem 0;
}