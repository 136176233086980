.deleteModal, .noteModal {
  .btn {
    margin: 1em 1em;
  }
  .add {
    background-color: #05A346;
    float: left;
  }
  .cancel, .delete {
    background-color: #EF4136;
  }
}

.noteModal {
  .name {
    border: none;
    border-bottom: 1px solid #484848;
    font-size: 1em;
    margin: 2em 0;
    padding: 0.5em 1em;;
    width: 50%;
  }
  .css-2b097c-container {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 2em;
  }
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1em 2em;
    padding: 0.75em 1em;
  }

  .content {
    .contentBackground {
      margin: 1em auto;
      max-width: 20rem;
      text-align: center;
      img {
        border: 1px dashed #000;
        border-radius: 10px;
        padding: 1em;
        cursor: pointer;
        width: auto;
        height: auto;
        max-width: 75%;
        max-height: 100%;
      }
    }
  }
}