.dice {
  height: 18rem;
  width: 18rem;
  margin: 0px auto;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  border-radius: 1rem;
  border: 1px dashed #000;

  .itemNote {
    width: 100%;
    flex: none;
    display: block;
    height: 100%;

    .content {
      height: 100%;
    }
  }
}