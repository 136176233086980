.parking {
  min-height: 18rem;
  min-width: 18rem;
  margin: 0px auto;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  border-radius: 1rem;
  border: 1px dashed #000;
  display: inline-block;

  .itemNote {
    width: 50%;
    height: 100%;

    .content {
      height: 100%;
    }
  }
}