.search {
  input {
    border-radius: 5rem;
    padding: 1.5rem 1rem;
    color: #676767;
    width: 50%;
    padding: 1.5rem;
    display: block;
    border: 1px solid #ced4da;
    margin: 0 auto;
  }
}