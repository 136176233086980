@import '../../assets/scss/variable.scss';

.sidenav {
    background-color: $primary;
    width: $sidebarWidth;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    img.logo {
        display: block;
        text-align: center;
        width: auto;
        height: auto;
        max-width: 15rem;
        margin: 5rem auto 8rem auto;
    }

    ul {
        margin: 0;
        padding: 0;
        float: right;
        list-style: none;
        width: 15rem;

        li {
            background-color: #ffffff21;
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 5rem;
            margin-bottom: 1rem;

            a {
                padding: 1.5rem;
                color: white;
                font-size: 1.4rem;
                text-decoration: none;
                display: block;
                transition: opacity .15s ease-in-out;

                &:hover {
                  opacity: .6;
                }

                img {
                    width: auto;
                    height: auto;
                    max-width: 2.25rem;
                    vertical-align: middle;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}