@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

html, body {
  margin: 0;
  padding: 0;
  color: #484848;
}

* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

html {
  font-size: 62.5%;

  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;

    @import './components/index.scss';
  }

  a {
    text-decoration: none;
    color: #484848;
  }
}