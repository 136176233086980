.Logs {
  padding: 2rem;

  h1 {
    text-align: center;
  }

  .nodata {
    padding: 1rem 0;
    text-align: center;
  }

  .filters {
    padding: 1rem 0;

    .filters-user {
      width: 15%;
      display: inline-block;
      margin-right: 1rem;
    }
    .filters-wine {
      width: 30%;
      display: inline-block;
    }
  }

  .tag {
    background-color: transparent;
    border-radius: 4px;
    color: #4a4a4a;
    font-size: 1rem;
    height: 2em;
    padding: .25rem .75rem;
    margin-right: 1rem;
  }

  #logs {
    border-collapse: collapse;
    width: 100%;
  }
  
  #logs td, #logs th {
    border: 1px solid #ddd;
    padding: 8px;
  }
    
 .tag.yellow {
    background-color: #ffc107;
  }
 .tag.green {
    background-color: #28a745;
  }
 .tag.red {
    background-color: #dc3545;
  }
  
  #logs th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: black;
    color: white;
  }

  #logs tr:nth-child(even){
    background-color: #f2f2f2;
  }

  #logs tr:hover {
    background-color: #ddd;
  }
}