.dices {
  padding: 1rem 6rem;
  display: inline-block;
  width: 65%;

  .dices-container {
    width: 100%;

    .dices-row {
      clear: both;
    }
  
    .middle-row {
      padding: 0rem 0;
    }
  }
}