@import '../../assets/scss/variable.scss';

.main-content {
    .Toastify__toast--default {
        background-color: $primary;
        color: white;
    }
    .Toastify__progress-bar--default {
        background: white;
    }
    .Toastify__close-button {
        color: white;
        opacity: 1;
    }
    
    .upload-content-container {
        text-align: center;
        color: $primary;
        width: 100%;
    
        img {
            display: block;
            width: auto;
            height: auto;
            max-width: 50rem;
            margin: 0 auto;
        }
    
        .txt-separator {
            margin: 1rem 0;
        }
    }
} 

.wine {
    display: flex;
    flex-direction: column;
    width: 100%;
}

