@import '../variable.scss';

button.btn {
    border: 0;
    padding: 1.25rem 5rem;
    background-color: $primary;
    color: white;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5rem;
    display: inline-block;
    width: auto;
    transition: opacity .3s ease-in-out;

    &:focus {
        outline: none;
    }
    &:hover {
        opacity: .7;
    }
}
button.btn.has-icon {
    img {
        width: 2rem;
        height: auto;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
    }
}